<!-- 表单预览 -->
<template>
  <a-modal
    :width="1000"
    :visible="visible"
    :confirm-loading="loading"
    :title="preview ? '表单预览' : isUpdate ? '修改成员' : '新增成员'"
    :body-style="{ paddingBottom: '8px' }"
    @update:visible="updateVisible"
  >
    <template #footer>
      <a-space v-if="!preview">
        <a-button type="info" @click="updateVisible(false)">取消</a-button>
        <a-button type="primary" @click="save">确认</a-button>
      </a-space>
    </template>
    <a-form
      ref="form"
      :model="form"
      :rules="rules"
      :label-col="{ md: { span: 7 }, sm: { span: 24 } }"
      :wrapper-col="{ md: { span: 17 }, sm: { span: 24 } }"
    >
      <a-row :gutter="16">
        <a-col
          :md="12"
          :sm="24"
          :xs="24"
          v-for="(item, index) in customFieldList"
          :key="index"
        >
          <a-form-item
            :label="item.label + ':'"
            :name="item.id"
            :required="item.verify"
          >
            <a-input
              v-if="item.type === '单行文本' || item.type === '身份证号'"
              allow-clear
              :maxlength="20"
              :placeholder="item.placeholder"
              v-model:value="form[item.id]"
            />
            <a-input
              v-if="item.type === '手机号'"
              allow-clear
              :maxlength="20"
              :placeholder="item.placeholder"
              v-model:value="form[item.id]"
            />
            <a-select
              v-if="item.type === '下拉框'"
              v-model:value="form[item.id]"
              placeholder="请选择"
              allow-clear
            >
              <a-select-option
                v-for="(value, index) in item.list"
                :key="index"
                :value="value.value"
                >{{ value.value }}</a-select-option
              >
            </a-select>
            <a-radio-group
              v-if="item.type === '单项选择'"
              v-model:value="listData[`${item.id}List`]"
            >
              <a-row>
                <a-col
                  :span="12"
                  v-for="(value, index) in item.list"
                  :key="index"
                >
                  <a-radio :value="value.value">{{ value.value }}</a-radio>
                </a-col>
              </a-row>
            </a-radio-group>
            <a-checkbox-group
              v-if="item.type === '多项选择'"
              v-model:value="listData[`${item.id}List`]"
            >
              <a-row>
                <a-col
                  :span="12"
                  v-for="(value, index) in item.list"
                  :key="index"
                >
                  <a-checkbox :value="value.value">{{
                    value.value
                  }}</a-checkbox>
                </a-col>
              </a-row>
            </a-checkbox-group>
            <div v-if="item.type === '描述'">
              <div>
                <div style="color: #e4e4e4">描述</div>
                <img
                  style="width: 120px; height: 120px"
                  :src="item.rules.photoDesc + '?width=120'"
                  alt=""
                />
              </div>
            </div>
            <div v-if="item.type === '团队'">
              <a-select
                v-if="!item.dataSource"
                v-model:value="listData[`${item.id}List`]"
                placeholder="请选择"
                allow-clear
              >
                <a-select-option
                  v-for="(value, index) in item.list"
                  :key="index"
                  :value="value.value"
                  >{{ value.value }}</a-select-option
                >
              </a-select>
              <a-select
                v-else
                v-model:value="listData[`${item.id}List`]"
                show-search
                :options="dataSourceList"
                placeholder="请选择"
                :filter-option="filterOption"
                @change="handleChange"
              >
                <!-- <a-select-option
                  v-for="(item, index) in schoolList"
                  :key="index"
                  :value="item.label"
                  >{{ item.label }}</a-select-option
                > -->
              </a-select>
            </div>
            <div v-if="item.type === '标签'">
              <a-checkable-tag
                v-for="(tag, index) in item.list"
                :key="index"
                :checked="listData[`${item.id}List`].indexOf(tag) > -1"
                @change="(checked) => handleTagChange(tag, checked, item.id)"
              >
                {{ tag }}
              </a-checkable-tag>
            </div>
            <a-input-number
              v-if="item.type === '数字'"
              v-model:value="form[item.id]"
              :min="item.rules.minCount || 0"
              :max="item.rules.maxCount"
              style="width: 100%"
              placeholder="请输入"
              allow-clear
            ></a-input-number>
            <a-textarea
              v-if="item.type === '多行文本'"
              allow-clear
              v-model:value="form[item.id]"
              placeholder="请输入"
            ></a-textarea>
            <a-date-picker
              style="width: 100%"
              v-if="item.type === '日期'"
              v-model:value="form[item.id]"
            />
            <a-time-picker
              style="width: 100%"
              v-if="item.type === '时间'"
              format="HH:mm"
              v-model:value="form[item.id]"
            />
            <div v-if="item.type === '地理位置'">
              <a-button type="primary" @click="coordinate()">地图选点</a-button>
              <span style="margin-left: 10px"
                >经度: {{ form.longitude }} 纬度: {{ form.latitude }}</span
              >
            </div>
            <a-cascader
              style="width: 100%"
              v-if="item.type === '省市区'"
              v-model:value="form[item.id]"
              :options="areaOptions"
              placeholder="请选择"
            />
            <div v-if="['单张图片', '多张图片', '健康码'].includes(item.type)">
              <div
                v-if="item.rules.sampleGraph"
                style="color: #0096d0; cursor: pointer"
                @click="previewSampleGraph(item.rules.sampleGraph)"
              >
                示例图
              </div>
              <a-upload
                name="picList"
                list-type="picture-card"
                v-model:file-list="fileList[`${item.id}List`]"
                @preview="handlePreview"
                :customRequest="({ file }) => uploadFile(file, item.id)"
                @remove="removeFile"
                :maxCount="item.maxCount || 1"
              >
                <div
                  v-if="
                    (fileList[`${item.id}List`] || []).length <
                    (item.maxCount || 1)
                  "
                >
                  <plus-outlined />
                  <div class="ant-upload-text">上传</div>
                </div>
              </a-upload>
              <a-modal
                :visible="previewVisible"
                :footer="null"
                :bodyStyle="{ paddingTop: '50px' }"
                @cancel="previewVisible = false"
              >
                <img alt="example" style="width: 100%" :src="previewImage" />
              </a-modal>
            </div>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
    <a-modal
      :width="950"
      :visible="showPostion"
      title="地图选址"
      :body-style="{ paddingBottom: '8px' }"
      @cancel="cancelPosition"
      @ok="setPosition"
    >
      <Map ref="map" v-model:position="position" id="pointAddress" />
    </a-modal>
  </a-modal>
</template>

<script>
import validate from '@/utils/validate'
import regions from '@/utils/regions.js'
import * as corpUserApi from '@/api/corp/corpUser.js'
import * as dataSourceApi from '@/api/activity/dataSource.js'
import setting from '@/config/setting'
import Map from '@/components/map/addressPicker.vue'
import { PlusOutlined } from '@ant-design/icons-vue'
function getBase64(pointPhoto) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(pointPhoto)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}
export default {
  name: 'UserEdit',
  emits: ['done', 'update:visible'],
  components: {
    PlusOutlined,
    Map
  },
  props: {
    // 弹窗是否打开
    visible: Boolean,
    formId: Number,
    data: Object,
    corpId: Number,
    fieldList: Array,
    isPreview: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      preview: this.isPreview,
      dataSourceList: [], // 数据源
      showPostion: false,
      position: {},
      areaOptions: regions.cityData,
      // 表单数据
      form: Object.assign({}, this.data),
      previewVisible: false,
      previewImage: '',
      fileList: {},
      listData: {},
      uploadUrl: setting.uploadUrl,
      // 表单验证规则
      rules: {
        username: [
          {
            required: true,
            type: 'string',
            trigger: 'blur',
            asyncValidator: (rule, value) => {
              return new Promise((resolve, reject) => {
                if (!value) {
                  return reject(new Error('请输入手机号'))
                }
                return resolve()
              })
            }
          }
        ],
        nickname: [
          {
            required: true,
            message: '请输入成员名',
            type: 'string',
            trigger: 'blur'
          }
        ],
        sex: [
          {
            required: true,
            message: '请选择性别',
            type: 'string',
            trigger: 'blur'
          }
        ],
        roleIds: [
          {
            required: true,
            message: '请选择角色',
            type: 'array',
            trigger: 'blur'
          }
        ],
        email: [
          {
            pattern: validate.email,
            message: '邮箱格式不正确',
            type: 'string',
            trigger: 'blur'
          }
        ],
        password: [
          {
            required: true,
            pattern: /^[\S]{5,18}$/,
            message: '密码必须为5-18位非空白字符',
            type: 'string',
            trigger: 'blur'
          }
        ],
        phone: [
          {
            pattern: validate.phone,
            message: '手机号格式不正确',
            type: 'string',
            trigger: 'blur'
          }
        ]
      },
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,
      // 角色列表
      roleList: [],
      // selectedTags: [],
      // 自定义字段
      customFieldList: []
    }
  },
  mounted() {
    console.log('form', this.form, 'isUpdate', this.isUpdate)
  },
  watch: {
    visible() {
      if (this.visible) {
        this.queryFormField()
      }
    },
    data() {
      console.log('ss')
      if (this.data) {
        this.form = Object.assign({}, this.data)
        console.log('form', this.form)
      } else {
        this.form = {}
      }
      if (this.data && this.data.id) {
        this.isUpdate = true
      } else {
        this.isUpdate = false
      }
      if (this.$refs.form) {
        this.$refs.form.clearValidate()
      }
      this.queryFormField()
    }
  },
  methods: {
    previewSampleGraph(url) {
      this.previewImage = url
      this.previewVisible = true
    },
    /* 保存编辑 */
    save() {
      const params = this.filterForm()
      this.$refs.form
        .validate()
        .then(() => {
          this.form = Object.assign({}, this.form, params)
          console.log('form', this.form)
          this.loading = true
          corpUserApi
            .save(this.form, this.isUpdate)
            .then((res) => {
              this.loading = false
              if (res.code === 0) {
                this.$message.success(res.msg)
                if (!this.isUpdate) {
                  this.form = {}
                }
                this.updateVisible(false)
                this.$emit('done')
              } else {
                this.$message.error(res.msg)
              }
            })
            .catch((e) => {
              this.loading = false
              this.$message.error(e.message)
            })
        })
        .catch(() => {})
    },
    // 下拉框搜索过滤
    filterOption(input, option) {
      return option.label.indexOf(input) >= 0
    },
    getDataSourceList(params) {
      dataSourceApi
        .getDataByParams(params)
        .then((res) => {
          if (res.code === 0) {
            this.dataSourceList = res.data.map((item) => {
              return {
                label: item.corpName,
                value: item.corpId
              }
            })
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    handleTagChange(tag, checked, id) {
      const nextSelectedTags = checked
        ? [...this.listData[`${id}List`], tag]
        : this.listData[`${id}List`].filter((t) => t !== tag)
      this.listData[`${id}List`] = nextSelectedTags
    },
    cancelPosition() {
      this.showPostion = false
    },
    coordinate() {
      this.postion = this.form
      this.showPostion = true
    },
    setPosition() {
      const data = this.$refs.map.form
      this.form.longitude = data.longitude
      this.form.latitude = data.latitude
      this.form.address = data.fullAddress
      this.showPostion = false
    },
    async handlePreview(pointPhoto) {
      if (!pointPhoto.url && !pointPhoto.preview) {
        pointPhoto.preview = await getBase64(pointPhoto.originFileObj)
      }
      this.previewImage = pointPhoto.url || pointPhoto.preview
      this.previewVisible = true
    },
    removeFile(file) {
      this.$http
        .delete('/file/remove', {
          path: file.url
        })
        .then((res) => {
          if (res.data.code === 0) {
            this.$message.success(res.data.msg)
          } else {
            this.$message.error(res.data.msg)
          }
        })
        .catch((err) => {
          this.$message.error(err)
        })
    },
    uploadFile(file, fieldId) {
      const formData = new FormData()
      formData.append('file', file)
      const uid = file.uid
      const hide = this.$message.loading('上传中..', 0)
      console.log(formData, file)
      this.$http
        .post(this.uploadUrl, formData)
        .then((res) => {
          hide()
          if (res.data.code === 0) {
            const obj = {
              uid: uid,
              name: 'image.png',
              status: 'done',
              url: res.data.location
            }
            const index = this.fileList[`${fieldId}List`].findIndex((item) => {
              return item.uid === uid
            })
            this.fileList[`${fieldId}List`].splice(index, 1, obj)
            this.form[fieldId] = this.arrToStr(
              this.fileList[`${fieldId}List`].map((item) => {
                return item.url
              })
            )
            this.$refs.form.validateFields(fieldId)
          } else {
            this.$message.error(res.data.msg)
          }
        })
        .catch((e) => {
          hide()
          this.$message.error(e.message)
        })
      return false
    },
    strToArr(str) {
      if (str) {
        const arr = str.split(',')
        return arr
      } else {
        return []
      }
    },
    arrToStr(arr) {
      if (arr.length > 0) {
        const str = arr.join(',')
        return str
      } else {
        return ''
      }
    },
    formatFieldList(arr) {
      return arr.map((item) => {
        let list
        if (item.listData) {
          list = this.strToArr(item.listData).map((item) => {
            return {
              name: item,
              value: item
            }
          })
        }
        return {
          dataSource: item.dataSource,
          dataScope: item.dataScope,
          label: item.label,
          distinct: item.distinct,
          hide: item.hide,
          id: item.fieldId,
          placeholder: item.placeholder,
          readonly: item.readonly,
          referrerId: item.referrerFieldId,
          type: item.type,
          list: list,
          rules: {
            minDate: item.minDate,
            maxDate: item.maxDate,
            minCount: item.minCount,
            maxCount: item.maxCount,
            dateDisplay: item.dateDisplay,
            errMsg: item.errMsg,
            fileList: item.fileList,
            label: item.label,
            miniProgram: item.miniProgram,
            name: item.id,
            photoDesc: item.photoDesc,
            provice: item.provice,
            sampleGraph: item.sampleGraph,
            uploadType: item.uploadType || '上传设置',
            value: '',
            verify: item.verify
          }
        }
      })
    },
    queryFormField() {
      if (this.preview) {
        console.log('fieldList', this.fieldList)
        this.customFieldList = this.formatFieldList(this.fieldList)
        this.customFieldList = this.customFieldList.map((item) => {
          if (
            item.type === '单项选择' ||
            item.type === '多项选择' ||
            item.type === '标签' ||
            item.type === '下拉框'
          ) {
            this.listData[`${item.id}List`] = this.strToArr(this.form[item.id])
            return item
          }
          if (
            item.type === '单张图片' ||
            item.type === '多张图片' ||
            item.type === '健康码'
          ) {
            this.fileList[`${item.id}List`] = this.strToArr(
              this.form[item.id]
            ).map((url, index) => {
              return {
                status: 'done',
                name: 'image.png',
                message: '',
                url: url,
                uid: index
              }
            })
            return item
          }
          if (item.type === '团队' && item.dataSource) {
            this.getDataSourceList({
              dataSource: item.dataSource,
              page: 1,
              limit: 1000
            })
            return item
          }
          if (item.type === '团队' && !item.dataSource) {
            this.listData[`${item.id}List`] = this.strToArr(this.form[item.id])
            return item
          }
          return item
        })
        console.log('fieldList2', this.customFieldList)
        return false
      }
      corpUserApi
        .fieldList({
          formId: 'corp-' + this.corpId,
          enable: true,
          sort: 'sortNumber',
          order: 'asc'
        })
        .then((res) => {
          if (res.code === 0) {
            this.customFieldList = this.formatFieldList(res.data)

            console.log('fieldList3', this.customFieldList)
            this.customFieldList = this.customFieldList.map((item) => {
              if (
                item.type === '单项选择' ||
                item.type === '多项选择' ||
                item.type === '标签' ||
                item.type === '团队'
              ) {
                this.listData[`${item.id}List`] = this.strToArr(
                  this.form[item.id]
                )
                return item
              }
              if (
                item.type === '单张图片' ||
                item.type === '多张图片' ||
                item.type === '健康码'
              ) {
                this.fileList[`${item.id}List`] = this.strToArr(
                  this.form[item.id]
                ).map((url, index) => {
                  return {
                    status: 'done',
                    name: 'image.png',
                    message: '',
                    url: url,
                    uid: index
                  }
                })
                return item
              }
              if (item.type === '团队' && item.dataSource) {
                this.getDataSourceList({
                  dataSource: item.dataSource,
                  page: 1,
                  limit: 1000
                })
                this.listData[`${item.id}List`] = this.strToArr(
                  this.form[item.id]
                )
                return item
              }
              if (item.type === '团队' && !item.dataSource) {
                this.listData[`${item.id}List`] = this.strToArr(
                  this.form[item.id]
                )
                return item
              }
              return item
            })
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    filterForm() {
      const params = {}
      this.customFieldList.forEach((item) => {
        if (
          item.type === '多项选择' ||
          item.type === '标签' ||
          item.type === '团队' ||
          item.type === '单项选择'
        ) {
          console.log('listData', this.listData)
          params[item.id] = this.arrToStr(this.listData[`${item.id}List`])
          this.form[item.id] = params[item.id]
          this.$refs.form.validateFields(item.id)
        }
        if (
          item.type === '单张图片' ||
          item.type === '多张图片' ||
          item.type === '健康码'
        ) {
          params[item.id] = this.arrToStr(
            this.fileList[`${item.id}List`].map((item) => {
              return item.url
            })
          )
        }
      })
      return params
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value)
    }
  }
}
</script>

<style lang="less" scoped>
</style>
